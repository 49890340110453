<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_company"></div>
          <div class="title-with-icon__title">Сведения об образовательной организации</div>
        </div>
        <company-navigation/>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "Information.vue",
  components: {CompanyNavigation, PageFooter},
  mounted() {
    this.$parent.init()
  }
}
</script>

<style scoped>

</style>